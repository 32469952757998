@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .pars-box {
    @apply rounded-lg border-2 bg-gray-50 p-4;
  }

  .dark .pars-box {
    @apply border-gray-600 bg-gray-950;
  }

  .pars-h1 {
    @apply scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl;
  }

  .pars-h2 {
    @apply scroll-m-20 pb-2 text-3xl font-semibold tracking-tight first:mt-0;
  }

  .pars-h3 {
    @apply scroll-m-20 text-2xl font-semibold tracking-tight;
  }

  .pars-h4 {
    @apply scroll-m-20 text-xl font-semibold tracking-tight;
  }
}

/* Userback styling */
.userback-button {
  margin-top: 10em !important;
}

@media (max-width: 640px) {
  .userback-button {
    top: 10% !important;
  }
}

:root {
  /* Replace your current variables with these */
  --highcharts-color-0: #312dff;
  --highcharts-color-1: #FFB000;
  --highcharts-color-2: #0074FF;
  --highcharts-color-3: #30BF2C;
  --highcharts-color-4: #FF6633;
  --highcharts-color-5: #AD03DE;
  --highcharts-color-6: #93F2FF;
  --highcharts-color-7: #085A9B;
  --highcharts-color-8: #00662C;
  --highcharts-color-9: #5F504D;

  /* Add these new variables that Highcharts actually uses in styled mode */
  --highcharts-color-primary: var(--highcharts-color-0);
  --highcharts-color-secondary: var(--highcharts-color-1);
  --highcharts-color-tertiary: var(--highcharts-color-2);

  /* For series colors */
  --highcharts-series-0-color: var(--highcharts-color-0);
  --highcharts-series-1-color: var(--highcharts-color-1);
  --highcharts-series-2-color: var(--highcharts-color-2);
  --highcharts-series-3-color: var(--highcharts-color-3);
  --highcharts-series-4-color: var(--highcharts-color-4);
  --highcharts-series-5-color: var(--highcharts-color-5);
  --highcharts-series-6-color: var(--highcharts-color-6);
  --highcharts-series-7-color: var(--highcharts-color-7);
  --highcharts-series-8-color: var(--highcharts-color-8);
  --highcharts-series-9-color: var(--highcharts-color-9);
}

.highcharts-partfill-overlay {
  filter: brightness(0.95);
  mix-blend-mode: multiply;
}